










import { Vue } from "vue-property-decorator";
import { Component } from "vue-property-decorator";
import PrivacyPolicy_DE from "@/views/legal/i18n/PrivacyPolicy_DE.vue";
import PrivacyPolicy_EN from "@/views/legal/i18n/PrivacyPolicy_EN.vue";

@Component({
  components: { PrivacyPolicy_EN, PrivacyPolicy_DE },
})
export default class PrivacyPolicy extends Vue {}
